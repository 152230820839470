.entriesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.entriesGrid {
  width: 100%;
}

.entry {
  height: 200px;
  min-width: 150px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  white-space: pre-line;
}

.entry:hover {
  box-shadow: rgb(66, 178, 253) 0px 6px 24px 0px,
    rgb(66, 178, 253) 0px 0px 0px 1px;
}
