.blockCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.width100 {
  width: 100%;
}

.signUpButton,
.logInButton,
.logOutButton,
.saveButton {
  border-color: #0d6efd;
  background-color: #0d6efd;
  margin: 3px;
}

.centeredStuff {
  display: flex;
  justify-content: center;
  align-items: center;
}
