.entryCard {
  background-color: white;
}

.cardBody {
  overflow: hidden;
  mask-image: linear-gradient(180deg, black 60%, transparent);
}

.cardText {
  white-space: pre-line;
}
