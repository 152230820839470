.aboutText {
  font-size: larger;
  color: white;
  text-align: center;
  margin: auto;
  width: 60%;
}

.linkedInLink {
  color: rgb(66, 178, 253);
  transition: color 0.15s ease-in-out;
  text-decoration: none;
}

.linkedInLink:hover {
  color: #0d6efd;
}

.thanks {
  margin-top: 1rem;
}

.border {
  border-style: solid;
  border-top: 0cap;
  border-left: 0cap;
  border-right: 0cap;
  border-width: 1px;
  border-color: rgb(66, 178, 253);
}
